.account-data-title {
    text-transform: uppercase;
    text-align: left;
    font-size: 14px;
    margin-bottom: 0px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.account-data-value {
    text-transform: capitalize;
    text-align: left;
    font-size: 12px;
    color: #4E84BA;
    font-weight: bold;
    margin-bottom: 5px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.column-align {
    text-align: left;
}