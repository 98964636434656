.card-home {
    height: 60px;
    width: 100%;
    border-radius: 0%;
}

.card-container {
    position: relative;
    height: 400px;
  }

.card-icon {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }

.card-body {
    padding: 3px;
    margin-top: 8px;
    margin-left: 25%;
}

.card-title, a:link {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
}

.card-subtitle {
    text-align: left;
    font-size: 10px;
    color: #8192AC
}