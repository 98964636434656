a:link {
    background-color: transparent;
    text-decoration: none;
    font-size: 8px;
    font-weight: normal;
    margin-left: 15px;
}

a:hover {
    color: #D1752F;
    background-color: transparent;
    text-decoration: none;
}

.link-text {
    color: #FFFFFF; 
    text-decoration: none;
    font-size: 12px !important;
}